<template>
  <div>
    <search-agent-monthly-bill @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="month" slot-scope="month, record">
        <router-link :to="{ name: 'monthly_bill_info', params: { id: record.id }}">
          {{ month }}
        </router-link>
      </div>

      <span slot="stopped_fee_title">
        <a-tooltip>
          <template slot="title">
            未出账时停机保号账单金额不计入月账单结算金额
          </template>
          停机保号账单金额(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="card_used_fee_title">
        <a-tooltip>
          <template slot="title">
            未出账时流量使用详情账单金额不计入月账单结算金额
          </template>
          流量使用详情账单金额(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import SearchAgentMonthlyBill from '@/views/agent_monthly_bills/Search'
import { findAgentMonthlyBills, exportAgentMonthlyBills } from '@/api/agent_monthly_bill'
import { formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentMonthlyBillList',
  components: {
    SearchAgentMonthlyBill,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {
      }
    }
  },
  computed: {
    agentId() {
      return this.$store.getters.userAgentId
    },

    agentType() {
      return this.$store.getters.userAgentType
    },

    columns() {
      var columnArr = [
        {
          title: '账单月份',
          dataIndex: 'month',
          scopedSlots: { customRender: 'month' }
        },
        {
          title: '账单状态',
          dataIndex: 'status'
        },
        {
          title: '采购账单金额(元)',
          customRender: formatCurrency,
          dataIndex: 'purchased_fee'
        },
        {
          title: '激活账单金额(元)',
          customRender: formatCurrency,
          dataIndex: 'actived_fee'
        },
        {
          title: '续期账单金额(元)',
          customRender: formatCurrency,
          dataIndex: 'renewed_fee'
        },
        {
          title: '套餐订购账单金额(元)',
          customRender: formatCurrency,
          dataIndex: 'subscribed_fee'
        },
        {
          slots: { title: 'stopped_fee_title' },
          customRender: formatCurrency,
          dataIndex: 'stopped_fee'
        },
        {
          slots: { title: 'card_used_fee_title' },
          customRender: formatCurrency,
          dataIndex: 'card_used_fee'
        },
        {
          title: '流量池订购账单金额(元)',
          customRender: formatCurrency,
          dataIndex: 'traffic_pool_subscribed_fee'
        },
        {
          title: '月账单结算金额(元)',
          customRender: formatCurrency,
          dataIndex: 'total_fee'
        }
      ]

      return columnArr
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentMonthlyBills(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }

        this.loading = false
      })
    },

    exportData() {
      exportAgentMonthlyBills(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

